import React, { Fragment, useEffect, useState } from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import BlogBanner from "../components/Blog/BlogBanner"
// import BlogContent from "../components/Blog/BlogContent" //html content renderer
import BlogTopic from "../components/Blog/BlogTopic"
import BlogWriters from "../components/Blog/BlogWriters"
import BlogFooterBanner from "../components/Blog/BlogFooterBanner"
import Seo from "../components/seo"
import ContentfulRichText from "../components/common/ContentfulRichText"
import { BLOCKS } from "@contentful/rich-text-types"
import BlogRelated from "../components/Blog/BlogRelated"
import { categoryFilter } from "../lib/utils"

export default function BlogPostTemplate(props) {
  const {
    data: { contentfulPost: data },
    pageContext: { postLinks },
  } = props
  console.log("BlogPostTemplate:props", props)
  let introContent, mainContent

  const documentNode = JSON.parse(data.content.raw)
  const nodes = documentNode.content
  const references = data.content.references

  let i = 0
  let introNodes = [],
    mainNodes = []
  while (i < nodes.length) {
    if (nodes[i].nodeType === BLOCKS.HEADING_2) {
      introNodes = nodes.slice(0, i - 1)
      mainNodes = nodes.slice(i)
      break
    }
    i++
  }

  // Insert blog summary node
  i = introNodes.findIndex(item => item.nodeType === BLOCKS.PARAGRAPH)
  introNodes.splice(i, 0, {
    nodeType: "blog_summary",
    data,
    content: [],
  })

  console.log(`blogPostTemplate:useEffect:i`, i)
  console.log(`blogPostTemplate:useEffect:nodes`, nodes)
  console.log(`blogPostTemplate:useEffect:references`, references)
  console.log(`blogPostTemplate:useEffect:introNodes`, introNodes)
  console.log(`blogPostTemplate:useEffect:mainNodes`, mainNodes)

  introContent = {
    raw: JSON.stringify({
      data: {},
      content: introNodes,
    }),
    references: references,
  }
  mainContent = {
    raw: JSON.stringify({
      data: {},
      content: mainNodes,
    }),
    references: references,
  }

  function renderContentTable() {
    if (!data.content) return
    const documentNode = JSON.parse(data.content.raw)
    const nodes = documentNode.content

    const headings = nodes
      .filter(
        obj =>
          obj.nodeType === BLOCKS.HEADING_2 || obj.nodeType === BLOCKS.HEADING_3
      )
      .map(obj => ({
        type: obj.nodeType,
        value: obj.content[0].value,
      }))

    if (data.slug === "what-is-alcoholism") return null
    return <BlogTopic topics={headings} />
  }

  function renderWriters() {
    if (!data.author && !data.editor && !data.reviewer) return null
    return (
      <BlogWriters
        author={data?.author}
        editor={data?.editor}
        reviewer={data?.reviewer}
      />
    )
  }

  function generateSeoData() {
    let description,
      meta = []

    meta.push({
      name: `google-site-verification`,
      content: process.env.GATSBY_GOOGLE_SITE_VERIFICATION,
    })

    const documentNode = JSON.parse(data.content.raw)
    const nodes = documentNode.content

    // og:image meta tag
    if (data.banner) {
      meta = [
        ...meta,
        {
          property: `og:image`,
          content: `https:${data.thumbnail.fixed.src}`,
        },
        {
          property: `og:image:alt`,
          content: data.title,
        },
        {
          property: `og:image:width`,
          content: data.thumbnail.fixed.width,
        },
        {
          property: `og:image:height`,
          content: data.thumbnail.fixed.height,
        },
      ]
    } else {
      const references = data.content.references
      let firstImageNode = references.find(
        obj => obj.file && obj.file.contentType === "image/jpeg"
      )
      if (firstImageNode) {
        meta = [
          ...meta,
          {
            property: `og:image`,
            content: `https:${firstImageNode.file.url}`,
          },
          {
            property: `og:image:alt`,
            content: data.title,
          },
          {
            property: `og:image:width`,
            content: firstImageNode.fixed.width,
          },
          {
            property: `og:image:height`,
            content: firstImageNode.fixed.height,
          },
        ]
      }
      console.log(`firstImageNode`, firstImageNode, meta)
    }

    // og:description
    if (data.description) {
      description = data.description
    } else {
      // /(^.*?[a-z]{2,}[.!?])\s+\W*[A-Z]/
      let firstParagraphNode = nodes.find(
        obj => obj.nodeType === BLOCKS.PARAGRAPH
      )
      description = firstParagraphNode.content[0].value
      if (description.length > 300) {
        description = description.substr(0, 300)
      }
    }

    return {
      title: data.title,
      description,
      meta,
    }
  }

  return (
    <Fragment>
      <Seo {...generateSeoData()} />
      {/* {data.banner && (
        <BlogBanner
          title={data?.title}
          bannerSrc={data?.banner?.fixed.src}
          shortDescription={data?.shortDescription?.shortDescription}
        />
      )} */}

      <Container>
        <Row>
          <Col sm={4}>
            <BlogRelated postLinks={categoryFilter(data, postLinks)} />
          </Col>
          <Col sm={8}>
            <div className="blog-container">
              {introContent && (
                <ContentfulRichText content={introContent} data={data} />
              )}
              {renderContentTable()}
              {mainContent && <ContentfulRichText content={mainContent} />}
              {renderWriters()}
            </div>
          </Col>
        </Row>
      </Container>
      <BlogFooterBanner />
    </Fragment>
  )
}

export const pageQuery = graphql`
  query PostDetailsBySlug($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      category {
        name
      }
      shortDescription {
        shortDescription
      }
      banner {
        fixed(width: 2000, quality: 100) {
          src
          width
          height
        }
      }
      thumbnail: banner {
        fixed(width: 1200, quality: 100) {
          src
          width
          height
        }
      }
      content {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            __typename
            id
            contentful_id
            title
            description
            file {
              contentType
              url
            }
            fixed(width: 900) {
              width
              height
              src
              srcSet
            }
          }
          ... on ContentfulTable {
            __typename
            id
            contentful_id
            name
            table {
              tableData
            }
          }
        }
      }
      createdAt
      updatedAt
      author {
        name
        position
        bio {
          bio
        }
        email
        twitterUsername
        avatar {
          fixed {
            src
            width
            height
            srcSet
          }
        }
      }
      reviewer {
        name
        position
        avatar {
          fixed {
            src
            width
            height
            srcSet
          }
        }
      }
      editor {
        name
        position
        avatar {
          fixed {
            src
            width
            height
            srcSet
          }
        }
      }
    }
  }
`
