import React from 'react'

export default function BlogRelated({ postLinks }) {
    return (
        <div className='blog__related'>
            <h2>Recommended Articles</h2>
            {postLinks.map(obj => (
                <div
                    key={obj.slug}
                    className="blog__related__item"
                >
                    <a target="_blank" href={obj.path}>{obj.title}</a>
                </div>
            ))}
        </div>
    )
}
